import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import { Link } from "gatsby"
import VideoBgHeroBlock from "./VideoBgHeroBlock"

const HeroBlock = ({ block, breadcrumbs, forceLoadImages = false }) => {
  const hasContent =
    breadcrumbs?.length > 0 ||
    block.headline?.length > 0 ||
    block.text?.length > 0 ||
    block.button?.length > 0

  return (
    <>
      {block.video?.filename?.length > 0 ? (
        <VideoBgHeroBlock block={block} breadcrumbs={breadcrumbs} />
      ) : (
        <>
          {block.image?.filename?.length > 0 && (
            <BlockWrapper
              block={block}
              blockPadding=""
              blockWidth="screen"
              showHeadline="false"
            >
              <div
                className={`relative mx-auto bg-black ${
                  block.reduced_height ? "md:h-[60vh]" : "md:h-vh-75"
                }`}
              >
                <div className="aspect-video md:h-full md:aspect-auto">
                  <Image
                    image={block.image}
                    title={block.title}
                    alt={block.alt}
                    className="object-cover w-full h-auto aspect-video md:h-full md:aspect-auto"
                    adaptToPixelRatio={true}
                    forceLoad={forceLoadImages}
                  />
                </div>
                {hasContent && (
                  <div
                    className={`bg-black md:bg-transparent md:inset-0 md:absolute ${
                      block?.use_gradient
                        ? "md:bg-gradient-to-r from-black-2/3-transparent to-transparent"
                        : ""
                    }`}
                  >
                    <div className="h-full max-w-screen-xl px-4 py-8 mx-auto">
                      <div className="flex flex-col justify-between h-full md:w-1/2">
                        <div>
                          <div className="block mb-2 -ml-2 text-sm">
                            {breadcrumbs?.map((breadcrumb, index) => (
                              <span key={breadcrumb.link}>
                                {index > 0 && (
                                  <span className="inline-block h-4 text-white">
                                    /
                                  </span>
                                )}
                                <Link
                                  className={`px-2 hover:underline text-white`}
                                  key={index}
                                  to={breadcrumb.link}
                                >
                                  {breadcrumb.link_text}
                                </Link>
                              </span>
                            ))}
                          </div>
                          <Headline
                            className="text-white"
                            headlineLevel="h1"
                            headline={block.headline}
                          />
                        </div>
                        <div>
                          <Richtext className="text-white" text={block.text} />
                          {block.buttons?.length > 0 && (
                            <div className="mt-8 -mb-4">
                              {block.buttons?.map((button, index) => (
                                <Button
                                  key={index}
                                  className={`mb-4 ${
                                    index + 1 < block.buttons.length
                                      ? "mr-4"
                                      : ""
                                  }`}
                                  btnType={
                                    index === 0 ? "primary " : "secondary"
                                  }
                                  link={button.link}
                                  target={
                                    button?.target === true ? "_blank" : ""
                                  }
                                  externalIcon={button.external_icon}
                                  icon={button.icon}
                                >
                                  {button.link_text}
                                </Button>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </BlockWrapper>
          )}
        </>
      )}
    </>
  )
}

export default HeroBlock
